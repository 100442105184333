@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');

body {
    margin: 0;
    padding: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(41, 103, 144, 1);
    /* https://codepen.io/mattyfours/pen/xNGdyO */
    background: radial-gradient(circle at 92% 16%, rgba(41, 103, 144, 1), rgba(41, 103, 144, 0) 70%),
        radial-gradient(circle at 10% 10%, rgba(139, 76, 107, 1), #dbab93 70%),
        radial-gradient(circle at 41% 23%, rgba(94, 179, 220, 1), rgba(94, 179, 220, 0) 70%),
        radial-gradient(circle at 71% 79%, rgba(58, 141, 194, 1), rgba(58, 141, 194, 1) 70%),
        radial-gradient(circle at 22% 81%, rgba(200, 106, 111, 1), rgba(200, 107, 111, 1) 70%);
    color: rgb(202, 223, 243);
    background-attachment: fixed;
    background-size: 100% 100%;
}

a {
    color: rgb(130, 192, 255);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

canvas,
#root,
body,
html {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
